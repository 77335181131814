import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import NewsStrip from "../components/news-strip"

class ServicePage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Our fleet" description="Explore our fleet of 60+ vehicles offered for a range of bus &amp; coach services in Yorkshire." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent headblock page-article">
            <h1 className="headblock__heading">Our fleet</h1>
            <p className="headblock__byline">Explore our range of vehicle types within our 60+ strong fleet</p>
          </div>
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div className="news-article-body">
              <div id="services" className="block-services__list-parent">
                <ul className="block-services__list">
                  <li><Link to="/fleet/minibus-hire/">16 seat minibus</Link></li>
                  <li><Link to="/fleet/32-35-seat-midi-bus/">32/35 seat midi bus</Link></li>
                  <li><Link to="/fleet/49-53-seat-coach/">49/53 seat coach</Link></li>
                  <li><Link to="/fleet/57-seat-school-bus/">57 seat school bus</Link></li>
                </ul>
              </div>
              <div id="services" className="block-services__list-parent">
                <ul className="block-services__list">
                  <li><Link to="/fleet/double-decker/">74 seat double decker</Link></li>
                  <li><Link to="/fleet/70-seat-coach/">70 seat school coach</Link></li>
                  <li><Link to="/fleet/disabled-access-coach/">Disabled access coach</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default ServicePage
